.booking-search {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .search-form {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 200px;
  }
  
  .form-group label {
    margin-bottom: 5px;
  }
  
  .form-group input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .results {
    margin-top: 20px;
  }
  
  .booking-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .booking-details {
    flex-grow: 1;
  }
  
  .line1, .line2 {
    margin: 2px 0;
  }
  
  .actions {
    display: flex;
    gap: 10px;
  }
  
  .edit-icon, .delete-icon {
    cursor: pointer;
    font-size: 18px;
  }
  
  .edit-icon:hover {
    color: #007bff;
  }
  
  .delete-icon:hover {
    color: #dc3545;
  }