.custom-table .ant-table-cell {
    padding: 0.05rem !important;
  }
  
  /* Default table layout for larger screens */
  .custom-table .ant-table-tbody > tr > td {
    display: table-cell;
  }
  
  /* Mobile layout: stack cells in two rows with labels above data */
  @media (max-width: 768px) {
    .custom-table .ant-table-tbody > tr {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 3 columns per row */
      gap: 0 0rem;
      padding: 0.05rem 0;
      border-bottom: 1px solid #848484;
      cursor: pointer; /* Indicate tappability */
      transition: background-color 0.2s; /* Smooth hover effect */
    }

    .custom-table .ant-table-tbody > tr:hover {
      background-color: #858585; /* Hover effect for feedback */
      /*transition: background-color 0.2s; /* Smooth hover effect */
    }

    .custom-table .ant-table-tbody > tr > td {
      display: flex;
      flex-direction: column;
      padding: 0.125rem 0.05rem !important;
      border: none;
      border-right-color: none;
      background-color: none;
    }
  
    /* Hide column headers on mobile */
    .custom-table .ant-table-thead {
      display: none;
    }
  
    /* Add labels above each cell content */
    .custom-table .ant-table-tbody > tr > td:nth-child(1)::before {
      content: "AWB";
      font-weight: bold;
      font-size: small;
      margin-bottom: 0rem;
      background-color: none  !important;
    }
    .custom-table .ant-table-tbody > tr > td:nth-child(2)::before {
      content: "Route";
      font-weight: bold;
      font-size: small;
      margin-bottom: 0.1rem;
    }
    .custom-table .ant-table-tbody > tr > td:nth-child(3)::before {
      content: "Agent";
      font-weight: bold;
      font-size: small;
      margin-bottom: 0rem;
    }
    .custom-table .ant-table-tbody > tr > td:nth-child(4)::before {
      content: "Date";
      font-weight: bold;
      font-size: small;
      margin-bottom: 0rem;
    }
    .custom-table .ant-table-tbody > tr > td:nth-child(5)::before {
      content: "Pieces";
      font-weight: bold;
      font-size: small;
      margin-bottom: 0rem;
    }
    .custom-table .ant-table-tbody > tr > td:nth-child(6)::before {
      content: "Weight";
      font-weight: bold;
      font-size: small;
      margin-bottom: 0rem;
    }
  }