/* --------------  CUSTOM ANT FORMS FORMAT ----------------- */
.ant-menu-item{
    font-size: 12px; /* Adjust this value to make the font smaller*/
    padding: 0; /* Remove padding */
    text-decoration: none; /* Remove underline */
    color: #000; /* Dark grey text color */
}

    .ant-form-item-explain {
        font-size: 12px; /* Adjust this value to make the font smaller*/
    }
.ant-form-item-control-input {
    min-height: 0;
    align-items: center;
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}    
.ant-form-item-explain-error {
    font-size: 12px; /* Adjust this value to make the font smaller*/
}
.ant-form-item-label {
    /*font-size: 12px !important;  Adjust this value to make the font smaller*/
    padding-bottom: 0rem !important; /* Reduce the margin between label and input */
  }

.ant-input[disabled] {
    /*font-size: 12px; /* Adjust this value to make the font smaller*/
    color: #000; /* Dark grey text color for disabled inputs */
    background-color: none; /* Light grey background for disabled inputs */
    border: 1px none #d9d9d9; /* Keep the border but make it lighter */
  }

/*Styles for the List*/
.flight-list .listitem { /*Adjust margin within List Lines*/
    margin-bottom: auto;
}
.flight-list .ant-input {
    padding: 0;
    padding: 0;
    justify-content: center;
    text-align: center;
  }
  .flight-list .ant-form-item-control-input {
    min-height: 0;
    align-items: center;

  }  
  .flight-list .ant-input[disabled] {
    font-size: 12px; /* Adjust this value to make the font smaller*/
    color: #000; /* Dark grey text color for disabled inputs */
    background-color: none; /* Light grey background for disabled inputs */
    border: 1px none #d9d9d9; /* Keep the border but make it lighter */
  }
  .remove-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    vertical-align: middle;
  }